/* global Office */
import React, { Component } from "react";
import { wrapTextInSmallOoxml, wrapBodyInOoxml, getBodyFromOoxml } from "../utils/helpers";

/**
 * Component to show a single rule
 */
export default class RuleItem extends Component {
  state = { hasEndings: false, show: false, focusedOn: null };
  textUpdates = {};
  activeInput = null;
  inputs = {};
  lastUpdate = new Date().getTime();

  /**
   * Check if there are flexible endings or not with this rule
   * and auto-adjust height of textarea element on first load
   */
  componentDidMount() {
    const hasEndings = this.props.type === "text" && Object.keys(this.props.inputs).length > 1;
    this.setState({ hasEndings: hasEndings });
    Object.keys(this.inputs).forEach(inputId => {
      this.handleChange({ type: "resize", target: this.inputs[inputId] }, inputId);
    });
  }

  /**
   * Show flexible endings when the first rule is focused on
   * @param {object} event - onFocus DOM event
   * @param {string} inputId - ID of input rule
   */
  handleFocus = ({ target }, inputId) => {
    this.textUpdates[inputId] = target.value;
    this.activeInput = inputId;
    this.setState({ focusedOn: inputId });
    target.select();
    if (!this.state.hasEndings) return;
    this.lastUpdate = new Date().getTime();
    this.setState({ show: true });
  };

  /**
   * Logic to hide flexible ending when unfocusing on rule
   * This include a 0.2 second timeout to make sure that the blur event doesn't occur
   * while focusing on the input of a flexible ending within this rule
   * @param {object} event - onFocus DOM event
   * @param {string} inputId - ID of input rule
   */
  handleBlur = ({ target: { value } }, inputId) => {
    if (this.activeInput === inputId) this.activeInput = null;
    if (this.textUpdates[inputId] !== value) this.updateText(inputId);
    setTimeout(
      function() {
        if (this.activeInput === null) this.setState({ focusedOn: null });
        if (!this.state.hasEndings) return;
        const now = new Date().getTime();
        const difference = now - this.lastUpdate;
        if (difference > 500) this.setState({ show: false });
      }.bind(this),
      200
    );
  };

  /**
   * Event handle on change on input/textarea field
   * @param {object} event - onchange event on element
   * @param {string} input - ID of the input field
   */
  handleChange = (event, input) => {
    //  update height of textarea to conform to content within
    let textarea = event.target;

    const oldHeight = textarea.style.height;
    textarea.style.height = "0px";
    const newHeight = Math.min(textarea.scrollHeight, 200) + "px";
    textarea.style.height = newHeight;

    if (newHeight !== oldHeight) {
      let { rule } = this.props;
      rule.inputs[input].height = newHeight;
      this.props.updateRule(this.props.ruleId, rule);
    }

    //  update value in binding if event type is not resize.
    if (event.type === "resize") {
      return;
    }
    this.props.onRuleTextChange(event, input);
  };

  /**
   * Update all binding in document for current freetext rule with text data from state
   * This method has been introduced to improve performance by updating the document on demand only
   * @param {string} inputId - ID of input in rule to update
   */
  updateText = async inputId => {
    // console.log("DEBUG: RuleItem - updateText");
    const { rule, setDocumentEditable, toggleLoading, isEditing } = this.props;

    toggleLoading();
    await setDocumentEditable(true);

    let { text, bindings } = rule.inputs[inputId];
    if (!Office.context.requirements.isSetSupported("WordApi", 1.3) && !text) {
      // PwC version of word does not support empty string for insertText-call to bindings. OOXML is ok.
      text = " ";
    }

    // bindings to update later on as they are outside body. i.e. in header or footer
    let postUpdateBindings = [];

    this.runInWord(context => {
      const body = context.document.body;
      const ooxml = body.getOoxml();
      return context.sync().then(() => {
        let contents = ooxml.value.toString();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(contents, "text/xml");
        const tags = xmlDoc.getElementsByTagName("w:tag");

        for (let tag of tags) {
          const bindingId = tag.getAttribute("w:val");
          if (bindings.indexOf(bindingId) === -1) continue;
          if (rule.bindings[bindingId].outsideBody === true) {
            if (rule.type === "text") postUpdateBindings.push({ id: bindingId, text: text });
            continue;
          }

          const textOoxml = wrapTextInSmallOoxml(text);
          const textDoc = new DOMParser().parseFromString(textOoxml, "text/xml");
          const sourceElm = textDoc.getElementsByTagName("w:t")[0];
          const textElms = tag.parentNode.parentNode.getElementsByTagName("w:t");
          // Quality control to ensure that there's only one w:t in text binding
          if (textElms.length > 1) {
            for (let i = 1; i < textElms.length; i++) {
              const textElm = textElms[i];
              textElm.parentNode.removeChild(textElm);
            }
          }
          const targetObj = textElms[0];
          const parent = targetObj.parentNode;
          parent.replaceChild(sourceElm, targetObj);
        }
        const serializer = new XMLSerializer();
        let newOoxml = serializer.serializeToString(xmlDoc);
        newOoxml = wrapBodyInOoxml(contents, getBodyFromOoxml(newOoxml));

        const lockTags = [
          `<w:lock w:val="sdtLocked" />`,
          '`<w:lock w:val="contentLocked" />`',
          `<w:lock w:val="contentLocked"/>`,
          `<w:lock w:val="sdtLocked" />`
        ];
        lockTags.forEach(lockTag => {
          while (newOoxml.indexOf(lockTag) !== -1) newOoxml = newOoxml.replace(lockTag, "");
        });

        body.insertOoxml(newOoxml, "Replace");
        return context.sync().then(async () => {
          if (postUpdateBindings.length > 0) {
            await this.postUpdate(postUpdateBindings);
          }
          if (!isEditing) await setDocumentEditable(false);
          toggleLoading();
        });
      });
    });
  };

  /**
   * Logic to update text bindings in header or footer after updating the body
   * @param {array} bindings
   */
  postUpdate = bindings => {
    const { setDocumentEditable, isEditing } = this.props;
    return new Promise(async resolve => {
      await setDocumentEditable(true, true);
      for (let binding of bindings) {
        await this.setTextInBinding(binding.id, binding.text);
      }
      await setDocumentEditable(false, isEditing);
      resolve();
    });
  };

  /**
   * Set text in a binding
   * @param {string} bindingId
   * @param {string} text
   */
  setTextInBinding = (bindingId, text) => {
    return new Promise(resolve => {
      this.runInWord(context => {
        const docBindings = context.document.contentControls.getByTag(bindingId);
        docBindings.load("id");
        return context.sync().then(() => {
          if (docBindings.items.length === 0) {
            return resolve();
          }
          const binding = docBindings.items[0];
          binding.insertText(text, "Replace");
          return context.sync().then(resolve);
        });
      });
    });
  };

  /**
   * Helper method to execute a function in Word API and handle errors
   * @param {function} method function to execute in Word API
   */
  runInWord(method) {
    /* eslint-disable no-undef */
    Word.run(method.bind(this)).catch(error => {
      console.log("Error: " + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
    /* eslint-enable */
  }

  /**
   * Component layout
   * @returns JSX
   */
  render() {
    const { rule } = this.props;

    const helperText = (rule.helperText || "").trim() || null;

    //  Don't show dependent rules if show condition doesn't match
    const isDependent = rule.dependent && rule.dependent.rule;
    if (isDependent) {
      const selectedToggle = this.props.rules[rule.dependent.rule].selectedToggle;
      if (rule.dependent.options.indexOf(selectedToggle) === -1) return null;
    }

    //  Buttons for toggle rules
    let toggles = Object.entries(this.props.toggles);
    let rows = [];
    for (let i = 0; i < toggles.length; i++) {
      if (!rows.length) rows.push({ position: i, toggles: [] });
      let lastRow = rows[rows.length - 1];
      if (lastRow.toggles.length === 2) {
        rows.push({ position: i, toggles: [] });
        lastRow = rows[rows.length - 1];
      }
      lastRow.toggles.push(toggles[i]);
    }

    const buttons = rows.map(row => {
      const labels = row.toggles.map((toggle, index) => {
        let labelClass = "w-100 btn btn-secondary";
        if (toggle[1].checked) labelClass += " duplidoc-purple-light-bg";
        return (
          <label
            onClick={() => this.props.onToggleChange(toggle[0])}
            key={toggle[0]}
            className={labelClass}
          >
            <input type="radio" name={toggle[0]} autoComplete="off" />
            {toggle[1].name}
          </label>
        );
      });

      return (
        <div
          key={row.position}
          className="w-100 btn-group btn-group-toggle"
          data-toggle="buttons"
          style={{ borderLeft: `15px solid ${this.props.color}` }}
        >
          {labels}
        </div>
      );
    });

    //  Input boxed for text fules
    const inputs = Object.entries(this.props.inputs).map((input, index) => {
      //  logic to hide flexible ending input fields if first field is not focused on
      if (this.state.hasEndings && !this.state.show && index > 0) return null;

      return (
        <div className="mb-3" key={index}>
          <div className="input-group">
            <div style={{ width: "15px" }} className="input-group-prepend">
              <span
                style={{
                  height: input[1].height,
                  backgroundColor: `${this.props.color}`
                }}
                className="input-group-text"
              />
            </div>
            <textarea
              onBlur={event => this.handleBlur(event, input[0])}
              onFocus={event => this.handleFocus(event, input[0])}
              ref={elm => {
                this.inputs[input[0]] = elm;
              }}
              onChange={e => this.handleChange(e, input[0])}
              value={input[1].text}
              className="freetextarea"
              style={{ height: input[1].height, overflow: "hidden" }}
            />
          </div>
          {this.state.focusedOn === input[0] && (
            <div className="text-right">
              <button
                tabIndex="-1"
                className="btn btn-xs btn-primary duplidoc-purple-bg btn-text-update"
              >
                Uppdatera
              </button>
            </div>
          )}
        </div>
      );
    });

    return (
      <div className="rule mt-3 mb-3">
        <div className="pl-0">
          <div className="rule-title-row">
            <span className="p-0 m-0  ms-fontWeight-semibold ms-font-m">
              {this.props.name}
              {isDependent && (
                <i className="ms-Icon ms-Icon--Link icon-dependent" aria-hidden="true" />
              )}
            </span>
          </div>
          {helperText && (
            <div className="d-block" style={{ fontSize: "11px", marginTop: "-5px" }}>
              {helperText}
            </div>
          )}
          <div className="d-flex">
            <div className="w-100">
              {this.props.type === "toggle" && <div className="vt-buttons">{buttons}</div>}
              {this.props.type === "text" && <div>{inputs}</div>}
            </div>
            {this.props.isEditing && (
              <div
                style={{ height: "38px" }}
                className="ml-1 d-flex flex-column justify-content-start"
              >
                <button
                  onClick={this.props.onRuleEditClick}
                  type="button"
                  className="btn btn-link  text-muted p-0 edit-button"
                >
                  Ändra
                </button>
                <button
                  onClick={this.props.onRuleRemoveClick}
                  type="button"
                  className="btn btn-link text-muted p-0 edit-button"
                >
                  Ta Bort
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
